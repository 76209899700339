/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';

import { api } from '../../utils/api';

export interface IUser {
  username: string;
  role: string;
  accessToken: string;
}

// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
export const API_URL = process.env.REACT_APP_API_URL || 'https://0.0.0.0:9001';

interface IAuthContext {
  isLoggedIn: boolean;
  user: IUser | null;
  authenticate?: (
    username: string,
    password: string,
    onSuccess?: (response: IUser) => void,
    onError?: (error: any) => void
  ) => void;
  logout?: () => void;
}

export const AuthContext = createContext<IAuthContext>({
  isLoggedIn: false,
  user: null,
});

interface IAuthProvider {
  children: ReactNode;
}

export const AuthProvider = ({ children }: IAuthProvider) => {
  const [session, setSession] = useState<{
    isLoggedIn: boolean;
    user: IUser | null;
  }>({
    isLoggedIn: false,
    user: null,
  });

  useEffect(() => {
    const userStringified = localStorage.getItem('auth');
    if (!userStringified) {
      return;
    }

    const user: IUser = JSON.parse(userStringified) as IUser;

    setSession({ isLoggedIn: true, user });
  }, []);

  const authenticate = useCallback(
    (
      username: string,
      password: string,
      onSuccess?: (response: IUser) => void,
      onError?: (error: any) => void
    ) => {
      api
        .post<IUser, { username: string; password: string }>(
          '/login',
          {
            username,
            password,
          },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
            },
          }
        )
        .then((response) => {
          localStorage.setItem('auth', JSON.stringify(response));

          setSession({
            isLoggedIn: true,
            user: response,
          });

          onSuccess?.(response);
        })
        .catch((error) => {
          setSession({ isLoggedIn: false, user: null });
          onError?.(error);
        });
    },
    []
  );

  const logout = useCallback(() => {
    localStorage.removeItem('auth');
    setSession({
      isLoggedIn: false,
      user: null,
    });
  }, []);

  const value = useMemo(
    () => ({
      authenticate,
      logout,
      isLoggedIn: session.isLoggedIn,
      user: session.user,
    }),
    [authenticate, logout, session]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

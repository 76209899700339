import type { ReactElement } from 'react';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthContext } from '../context/auth/AuthContext';

interface IAuthRoute {
  children: ReactElement;
}

export const AuthGuard = ({ children }: IAuthRoute) => {
  const { isLoggedIn } = useContext(AuthContext);

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

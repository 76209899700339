import type { ReactElement } from 'react';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthContext } from '../context/auth/AuthContext';

interface INonAuthRoute {
  children: ReactElement;
}

export const NonAuthGuard = ({ children }: INonAuthRoute): ReactElement => {
  const { isLoggedIn } = useContext(AuthContext);

  if (isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return children;
};

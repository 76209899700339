/* eslint-disable react/forbid-dom-props */
import type { ReactElement, ReactNode } from 'react';

interface ISwitchProps {
  name: string;
  value: boolean;
  onChange: (value: boolean) => void;
  children?: ReactNode;
  className?: string;
}

export const Switch = ({
  name,
  value,
  children,
  className = '',
  onChange,
}: ISwitchProps): ReactElement => {
  return (
    <label
      className={`flex items-center cursor-pointer select-none ${className}`}
      htmlFor={name}
    >
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={value}
        onChange={(evt) => onChange(evt.target.checked)}
        className="hidden"
      />

      <div
        className={`w-14 h-8 p-1 rounded-full transition ${
          value
            ? 'bg-blue-600 hover:bg-blue-700'
            : 'bg-gray-200 hover:bg-gray-300'
        }`}
      >
        <div
          className="w-6 h-6 rounded-full bg-white transition"
          style={{
            transform: value ? 'translateX(100%)' : 'translateX(0%)',
          }}
        />
      </div>

      {children && <div className="pl-3">{children}</div>}
    </label>
  );
};

Switch.defaultProps = {
  className: undefined,
  children: undefined,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import Axios from 'axios';
import { useEffect, useState } from 'react';

import { api } from './api';

export function useRequest<TResponse>(
  url: string,
  defaultValue: TResponse,
  options?: Record<string, any>
) {
  const [state, setState] = useState<TResponse>(defaultValue);
  const [canRefreshToken, setCanRefreshToken] = useState(false);

  useEffect(() => {
    const source = Axios.CancelToken.source();

    if (!url.includes('undefined')) {
      api
        .get<TResponse>(url, { cancelToken: source.token, ...options })
        .then((response) => {
          setState(response);
        })
        .catch((error) => {
          if (Axios.isCancel(error)) {
            return error;
          }
          // eslint-disable-next-line no-console
          // console.log(error);
        });
    }

    return () => source.cancel();
  }, [url, canRefreshToken, options]);

  return [
    state,

    {
      refresh() {
        setCanRefreshToken((prev) => !prev);
      },
    },
  ] as const;
}

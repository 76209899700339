import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { Main } from '../components/Main';

export const HomePage = () => {
  return (
    <div className="flex flex-col h-full w-full">
      <Header />
      <Main />
      <Footer />
    </div>
  );
};

/* eslint-disable @typescript-eslint/no-misused-promises */
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { AuthContext } from '../context/auth/AuthContext';

interface ILoginForm {
  username: string;
  password: string;
}

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginForm>({
    mode: 'onChange',
  });
  const { authenticate } = useContext(AuthContext);
  const navigate = useNavigate();

  const onSubmit = (data: ILoginForm) => {
    authenticate?.(
      data.username,
      data.password,
      () => {
        navigate('/');
      },
      () => {
        // eslint-disable-next-line no-console
        // console.log(error);
      }
    );
  };

  return (
    <div className="flex-1 w-full h-full flex flex-col justify-center items-center">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-96">
        <input
          type="text"
          {...register('username', { required: true })}
          className={`mb-2 p-3 rounded ${
            errors.password ? 'border border-red-700' : ''
          }`}
          placeholder="Užívateľské meno"
        />
        <input
          type="password"
          {...register('password', { required: true })}
          className={`mb-2 p-3 rounded ${
            errors.password ? 'border border-red-700' : ''
          }`}
          placeholder="Heslo"
        />
        <button
          type="submit"
          className="rounded text-white bg-blue-600 hover:bg-blue-700 py-3"
        >
          Prihlasiť sa
        </button>
        {errors.password?.message || errors.username?.message ? (
          <div className="rounded mt-3 bg-red-100 border border-red-700 p-3 flex flex-col">
            <span className="mb-1 text-gray-700">
              {errors.username?.message}
            </span>
            <span className="text-gray-700">{errors.password?.message}</span>
          </div>
        ) : null}
      </form>
    </div>
  );
};

export const LoginPage = () => {
  return (
    <div className="flex flex-col h-full w-full">
      <Header />
      <LoginForm />
      <Footer />
    </div>
  );
};

/* eslint-disable no-console */
import { useCallback, useContext, useState } from 'react';

import { AuthContext } from '../context/auth/AuthContext';
import type { IGroup } from '../context/hall/HallContext';
import { CurrentSectionContext } from '../context/hall/HallContext';
import { api } from '../utils/api';

interface IGroupInputProps {
  group: IGroup;
  measurement: { value: number; quality: number } | null;
  // eslint-disable-next-line react/require-default-props
  className?: string;
}

export const GroupInput = ({
  group,
  measurement,
  className,
}: IGroupInputProps) => {
  const { user } = useContext(AuthContext);
  const { currentSection } = useContext(CurrentSectionContext);

  const [intensity, setIntensity] = useState<string>('0');

  const handleIntensityChange = (value: string) => {
    if (value.includes('-')) {
      setIntensity((prev) => prev);
      return;
    }

    if (Number(value) > 100) {
      setIntensity('100');
      return;
    }

    if (Number(value) < 0) {
      setIntensity('0');
      return;
    }

    const newValue =
      value.startsWith('0') && value.length > 1 ? value.substring(1) : value;

    setIntensity(newValue);
  };

  const handleSetIntensity = useCallback(() => {
    currentSection &&
      api
        .put(
          `/group/modulate/${group.groupAddress}`,
          {
            value: intensity,
            sectionAddress: currentSection?.sectionAddress,
            apiAddress: currentSection?.apiAddress,
          },
          {
            headers: {
              Authorization: `Bearer ${user?.accessToken}`,
              'Access-Control-Allow-Origin': '*',
            },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [currentSection, group.groupAddress, intensity, user?.accessToken]);

  return (
    <div className={`w-full ${className}`}>
      <div className="flex mb-1">
        <label className="mr-5">{group.name}</label>
      </div>
      <div className="flex items-center justify-between">
        <div className="text-blue-600 mr-3 w-12">{measurement?.value} %</div>
        <span>
          <input
            type="number"
            min={0}
            max={100}
            className="mr-3 px-2 rounded w-20 py-2 text-center"
            value={intensity}
            onChange={(evt) => {
              handleIntensityChange(evt.target.value);
            }}
            onBlur={(evt) => {
              if (evt.target.value === '') {
                setIntensity('0');
              }
            }}
          />
          %
        </span>
        <button
          type="button"
          className="bg-blue-600 hover:bg-blue-700 text-white rounded px-3 py-2"
          onClick={handleSetIntensity}
        >
          Set
        </button>
      </div>
    </div>
  );
};

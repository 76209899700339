interface IHallButtonProps {
  name: string;
  isActive: boolean;
  onClick: () => void;
}

export const HallButton = ({ name, isActive, onClick }: IHallButtonProps) => {
  return (
    <button
      type="button"
      className={`p-3 border border-blue-600 rounded lg:h-24 lg:w-24 md:h-20 md:w-20 sm:w-16 sm:h-16 w-12 h-12 text-xs sm:text-sm md:text-base  ${
        isActive
          ? 'text-white bg-blue-600 hover:bg-blue-700'
          : 'text-gray-600 hover:bg-blue-200'
      }`}
      onClick={onClick}
    >
      {name}
    </button>
  );
};

import { useContext, useMemo } from 'react';
import ReactSelect from 'react-select';

import SAMKomarnoLogo from '../assets/logo/sam_komarno.png';
import { CurrentSectionContext } from '../context/hall/HallContext';

export const HallSelect = () => {
  const { currentSection, setCurrentSection, sections } = useContext(
    CurrentSectionContext
  );

  const options = useMemo(
    () =>
      sections.map((hall) => ({
        value: hall.id,
        label: hall.name,
      })),
    [sections]
  );

  return (
    <div className="sm:hidden m-3 flex flex-col justify-center items-center">
      <img src={SAMKomarnoLogo} alt="SAM_komarno_logo" className="mb-3" />
      <ReactSelect
        className="w-full"
        closeMenuOnSelect
        options={options}
        value={options.find((element) => element.value === currentSection?.id)}
        onChange={(value) => {
          value && setCurrentSection(value?.value);
        }}
      />
    </div>
  );
};

import type { ReactElement, ReactNode } from 'react';
import {
  useCallback,
  createContext,
  useState,
  useMemo,
  useContext,
  useEffect,
} from 'react';

import { api } from '../../utils/api';
import { AuthContext } from '../auth/AuthContext';

export interface IGroup {
  id: number;
  name: string;
  groupAddress: number;
}

interface ISection {
  id: number;
  name: string;
  apiAddress: string;
  apiWsAddress: string;
  opendafWsAddress: string;
  sectionAddress: number;
  _group: IGroup[];
}

interface ICurrentSection {
  currentSection: ISection | null;
  setCurrentSection: (sectionId: number) => void;
  sections: ISection[];
}

export const CurrentSectionContext = createContext<ICurrentSection>({
  currentSection: null,
  setCurrentSection: (sectionId: number) => {
    // eslint-disable-next-line no-console
    console.log(sectionId);
  },
  sections: [],
});

interface ICurrentHallProviderProps {
  children: ReactNode;
}

export const CurrentHallProvider = ({
  children,
}: ICurrentHallProviderProps): ReactElement => {
  const [currentSection, setCurrentSection] = useState<ISection | null>(null);
  const [sections, setSections] = useState<ISection[]>([]);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    api
      .get<ISection[]>('/sections', {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((response) => {
        setSections(response);
        setCurrentSection(response[0]);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [user?.accessToken]);

  const handleCurrentSectionChange = useCallback(
    (sectionId: number) => {
      const newSection = sections.find((section) => section.id === sectionId);
      if (!newSection) {
        return;
      }

      setCurrentSection(newSection);
    },
    [sections]
  );

  const value = useMemo(
    () => ({
      currentSection,
      setCurrentSection: handleCurrentSectionChange,
      sections,
    }),
    [currentSection, handleCurrentSectionChange, sections]
  );

  return (
    <CurrentSectionContext.Provider value={value}>
      {children}
    </CurrentSectionContext.Provider>
  );
};

import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

// export const API_URL = process.env.REACT_APP_API_URL ?? '';

const axiosApi = axios.create({
  baseURL: 'https://indoor.seakenergetics.com',
});

export const api = {
  async get<TResponse>(
    url: string,
    options: AxiosRequestConfig = {}
  ): Promise<TResponse> {
    return await axiosApi
      .get<TResponse>(url, { ...options })
      .then((response) => response.data);
  },

  async post<TResponse, TPayload>(
    url: string,
    data?: TPayload,
    options?: AxiosRequestConfig
  ): Promise<TResponse> {
    return await axiosApi
      .post<TResponse>(url, data, { ...options })
      .then((response) => response.data);
  },

  async put<TResponse, TPayload>(
    url: string,
    data?: TPayload,
    options?: AxiosRequestConfig
  ): Promise<TResponse> {
    return await axiosApi
      .put<TResponse>(url, data, { ...options })
      .then((response) => response.data);
  },

  async delete<TResponse>(url: string): Promise<TResponse> {
    return await axiosApi
      .delete<TResponse>(url)
      .then((response) => response.data);
  },
};

export interface IDigitalInput {
  id: number;
  name: string;
  address: number;
  description: string;
  falseLevelName: string;
  trueLevelName: string;
  polarity: string;
  purpose: string | null;
  sourceType: string;
  remoteIOId: number | null;
  remoteIOAddress?: number;
}

export enum DISourceTypes {
  LUMIMASTER = 'LUMIMASTER',
  MDI = 'MDI',
}

export interface IModel {
  _areas: IArea[];
}

export interface IArea {
  _id: number;
  _address: number;
  _name: string;
  _sections: ISection[];
}

export interface ISection {
  _id: number;
  _address: number;
  _ssrAddress: number;
  _ssrCircuit: string;
  _ssrId: number;
  _name: string;
  _groups: IGroup[];
}

export interface IGroup {
  _id: number;
  _address: number;
  _name: string;
}

export interface ISSR {
  id: number;
  address: number;
  name: string;
  description: string;
  position: string;
  circuit: string;
  status: boolean;
}

export const getName = (name: string) => {
  switch (name) {
    case 'KDR_MODE': {
      return 'Ovládanie cez aplikáciu';
    }
    case 'OVERRIDE': {
      return 'Ovládanie cez prepínače';
    }
    default: {
      return 'NULL';
    }
  }
};

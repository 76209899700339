import { useContext } from 'react';

import { ReactComponent as LogoutIcon } from '../assets/icons/logout.svg';
import { ReactComponent as SeakLogo } from '../assets/logo/seak.svg';
import { AuthContext } from '../context/auth/AuthContext';

export const Header = () => {
  const { isLoggedIn, logout } = useContext(AuthContext);

  return (
    <header className="px-5 py-4 bg-blue-600 flex items-center justify-between">
      <SeakLogo />
      {isLoggedIn ? (
        <button type="button" onClick={logout}>
          <LogoutIcon title="Logout" style={{ color: 'white' }} />
        </button>
      ) : null}
    </header>
  );
};

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useCallback, useContext, useEffect, useState } from 'react';
import type { Socket } from 'socket.io-client';
import { io } from 'socket.io-client';

import { GroupInput } from './GroupInput';
import { Switch } from './Switch';

import { AuthContext } from '../context/auth/AuthContext';
import { CurrentSectionContext } from '../context/hall/HallContext';
import { api } from '../utils/api';
import { getName } from '../utils/general';
import type { MeasurementsObject } from '../utils/measurement';
import { useRequest } from '../utils/useRequest';

export const HallController = () => {
  const { currentSection } = useContext(CurrentSectionContext);
  const { user } = useContext(AuthContext);
  // API socket
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [canRefreshMeasurements, setCanRefreshMeasurements] =
    useState<boolean>(false);
  // Measurements
  const [measurements, setMeasurememts] = useState<MeasurementsObject>();

  const [intensity, setIntensity] = useState<string>('0');

  const [controlMode, { refresh: refreshControlMode }] = useRequest<
    string | null
  >(`/section/control-mode/${currentSection?.id}`, null, {
    headers: {
      Authorization: `Bearer ${user?.accessToken}`,
      'Access-Control-Allow-Origin': '*',
    },
  });

  const [sectionMode, { refresh: refreshMode }] = useRequest<string | null>(
    `/section/mode/${currentSection?.id}`,
    null,
    {
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
        'Access-Control-Allow-Origin': '*',
      },
    }
  );

  useEffect(() => {
    let newSocket: Socket | null = null;
    if (user) {
      newSocket = io('https://indoor.seakenergetics.com:443', {
        query: { token: user.accessToken },
      });
    }

    setSocket(newSocket);

    return () => {
      if (newSocket) {
        newSocket.close();
      }
    };
  }, [user, setSocket]);

  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        setIsConnected(true);
      });

      socket.on('connect_error', () => {
        setIsConnected(false);
      });
    }
  }, [socket, setIsConnected]);

  useEffect(() => {
    const refreshDashboard = () => {
      refreshControlMode();
      refreshMode();
    };

    const measurementsListener = (message: any) => {
      setMeasurememts(message.measurements);
    };

    const refreshMeasurementsListener = () => {
      setCanRefreshMeasurements((prev) => !prev);
    };

    if (socket && isConnected) {
      socket.on('refresh-dashboard', refreshDashboard);
      socket.on('measurements', measurementsListener);
      socket.on('refresh-measurements', refreshMeasurementsListener);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, socket]);

  useEffect(() => {
    if (socket && isConnected && currentSection?.opendafWsAddress) {
      socket.emit('get-measurements', {
        opendafWsAddress: currentSection?.opendafWsAddress,
      });
    }
  }, [socket, isConnected, currentSection, canRefreshMeasurements]);

  const handleIntensityChange = (value: string) => {
    if (value.includes('-')) {
      setIntensity((prev) => prev);
      return;
    }

    if (Number(value) > 100) {
      setIntensity('100');
      return;
    }

    if (Number(value) < 0) {
      setIntensity('0');
      return;
    }

    const newValue =
      value.startsWith('0') && value.length > 1 ? value.substring(1) : value;

    setIntensity(newValue);
  };

  const handleSetIntensity = useCallback(() => {
    currentSection &&
      api
        .put(
          `/section/modulate/${currentSection.sectionAddress}`,
          { value: intensity, apiAddress: currentSection.apiAddress },
          {
            headers: {
              Authorization: `Bearer ${user?.accessToken}`,
              'Access-Control-Allow-Origin': '*',
            },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [currentSection, intensity, user?.accessToken]);

  const handleControlModeChange = useCallback(() => {
    currentSection &&
      api
        .put(
          `/section/control-mode/${currentSection.id}`,
          {
            controlMode: controlMode === 'AUTO' ? 'MANUAL' : 'AUTO',
          },
          {
            headers: {
              Authorization: `Bearer ${user?.accessToken}`,
              'Access-Control-Allow-Origin': '*',
            },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [controlMode, currentSection, user?.accessToken]);

  const currentValue =
    currentSection &&
    measurements?.[`M_AREA1_SECTION${currentSection.sectionAddress}`]
      ? measurements[`M_AREA1_SECTION${currentSection.sectionAddress}`].value
      : undefined;

  return (
    <div className="flex-1 flex flex-col justify-center items-center my-5 text-gray-500">
      <div className="w-full sm:w-72 px-5 sm:px-0">
        <div className="w-full">
          <div className="text-2xl text-gray-500 border-b-2 border-gray-400 pb-2 mb-2">
            {currentSection?.name}
          </div>
          {sectionMode === 'OVERRIDE' ||
          (controlMode === 'AUTO' && sectionMode === 'KDR_MODE') ? (
            <div className="mb-2">
              <div className="text-lg">Aktuálna intenzita:</div>
              <div className="font-semi-bold text-blue-600">
                {currentValue} %
              </div>
            </div>
          ) : null}
          <div className="mb-2">
            <div className="text-lg">Ovládanie:</div>
            <div className="font-semi-bold text-md text-blue-600">
              {getName(sectionMode ?? '')}
            </div>
          </div>
          {sectionMode === 'KDR_MODE' ? (
            <>
              <div className="mb-2">
                <span className="text-lg">Regulačný mód:</span>
                <div className="flex justify-between items-center">
                  <span>Manuálny</span>
                  <Switch
                    name="mode-switch"
                    onChange={handleControlModeChange}
                    value={controlMode === 'AUTO'}
                  />
                  <span>Automatický</span>
                </div>
              </div>
              {controlMode === 'MANUAL' ? (
                <div className="mb-1 w-full">
                  <div className="flex items-center justify-between">
                    <span>
                      <input
                        type="number"
                        min={0}
                        max={100}
                        className="mr-3 px-2 rounded w-20 py-2 text-center"
                        value={intensity}
                        onChange={(evt) => {
                          handleIntensityChange(evt.target.value);
                        }}
                        onBlur={(evt) => {
                          if (evt.target.value === '') {
                            setIntensity('0');
                          }
                        }}
                      />
                      %
                    </span>
                    <button
                      type="button"
                      className="bg-blue-600 hover:bg-blue-700 text-white rounded px-3 py-2"
                      onClick={handleSetIntensity}
                    >
                      Set
                    </button>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
        <div className="h-px w-full bg-gray-400 my-2" />
        {controlMode === 'MANUAL' && sectionMode === 'KDR_MODE' ? (
          <div className="w-full flex flex-col">
            {currentSection?._group.length
              ? currentSection._group.map((group, index) => {
                  return (
                    <div key={`${currentSection.id}_${group.groupAddress}`}>
                      <GroupInput
                        group={group}
                        className={
                          currentSection._group.length !== index + 1
                            ? 'mb-1'
                            : ''
                        }
                        measurement={
                          measurements
                            ? measurements[
                                `M_AREA1_SECTION${currentSection.sectionAddress}_GROUP${group.groupAddress}`
                              ]
                            : null
                        }
                      />
                      {currentSection._group.length !== index + 1 ? (
                        <div className="h-px bg-gray-200 my-1" />
                      ) : null}
                    </div>
                  );
                })
              : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

import { HallController } from './HallController';
import { HallList } from './HallList';
import { HallSelect } from './HallSelect';
import { VerticalDivider } from './VerticalDivider';

import { CurrentHallProvider } from '../context/hall/HallContext';

export const Main = () => {
  return (
    <div className="flex-1 flex flex-col sm:flex-row">
      <CurrentHallProvider>
        <HallList />
        <div className="hidden sm:flex">
          <VerticalDivider />
        </div>
        <HallSelect />
        <HallController />
      </CurrentHallProvider>
    </div>
  );
};

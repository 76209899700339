import { HashRouter, Route, Routes } from 'react-router-dom';

import { AuthGuard } from './components/AuthGuard';
import { NonAuthGuard } from './components/NonAuthGuard';
import { AuthProvider } from './context/auth/AuthContext';
import { HomePage } from './pages/HomePage';
import { LoginPage } from './pages/Login';

export const App = () => {
  return (
    <HashRouter>
      <AuthProvider>
        <Routes>
          <Route
            path="/login"
            element={
              <NonAuthGuard>
                <LoginPage />
              </NonAuthGuard>
            }
          />
          <Route
            path="/"
            element={
              <AuthGuard>
                <HomePage />
              </AuthGuard>
            }
          />
        </Routes>
      </AuthProvider>
    </HashRouter>
  );
};

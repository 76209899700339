import { useContext } from 'react';

import { HallButton } from './HallButton';

import SAMKomarnoLogo from '../assets/logo/sam_komarno.png';
import { CurrentSectionContext } from '../context/hall/HallContext';

export const HallList = () => {
  const { currentSection, setCurrentSection, sections } = useContext(
    CurrentSectionContext
  );

  return (
    <div className="hidden sm:flex flex-1 flex-col justify-center items-center">
      <img src={SAMKomarnoLogo} alt="SAM_komarno_logo" />
      <div className="text-2xl mb-5 text-gray-500">Zoznam hál</div>
      <div className="grid grid-cols-4 gap-4">
        {sections.map((section) => (
          <HallButton
            key={`hall_${section.id}`}
            name={section.name}
            isActive={currentSection ? section.id === currentSection.id : false}
            onClick={() => {
              currentSection &&
                currentSection.id !== section.id &&
                setCurrentSection(section.id);
            }}
          />
        ))}
      </div>
    </div>
  );
};
